<template>
	<div class="video">
		<div class="banner" v-if="this.isPc">
			<img src="static/server_banner.png"  width="100%" />
		</div>
		<div class="banner" v-if="!this.isPc">
			<img src="static/ask_banner.png"  width="100%" />
			<p class="sever_text">服务咨询</p>
		</div>
		<div class="video_title" >
			<div class="line"></div>
			<div class="name">常见问题</div>
			<div class="line"></div>
		</div>
		<div class="card_content">
			<el-tabs :tab-position="tabPosition">
			  <el-tab-pane label="开机问题" >
				  <p class="que">问：为什么智慧黑板开机后无法进入系统?</p>
				  <p class="ans">尊敬的用户，您好，请确认：</p>
				  <p class="ans">1、电源线插座电压和接地正常。</p>
				  <p class="ans">2、检查按键正常以及遥控器电池电量充足</p>
				  <p class="que">问：为什么答题器某个或多个按键不起作用？</p>
				  <p class="ans">亲爱的用户，你好</p>
				  <p class="ans">请检测按键是否有因为用力过猛无法弹起或者按键有灰尘挤压导致不起作用。</p>
			  </el-tab-pane>
			  <el-tab-pane label="显示问题">
				  <p class="que">问：为什么智慧黑板在HDMI通道输入无信号?</p>
				  <p class="ans">尊敬的用户，您好，请确认：</p>
				  <p class="ans">1、电源线插座电压和接地正常。</p>
				  <p class="ans">2、更换HDMI接口或者线材。</p>
				  <p class="ans">3、如果是外接电脑输入，请在电脑端设置HDMI输出设置。</p>
			  </el-tab-pane>
			  <el-tab-pane label="软件问题">
				  <p class="que">问：怎样重新安装系统？</p>
				  <p class="ans">亲爱的用户，你好</p>
				  <p class="ans">智能黑板内置系统是我们常用电脑模块系统，安装方法也一样，一键还原软件、U盘PE系统、系统光盘均可使用。</p>
				  <p class="que">问：为何播放的视频不够清晰？</p>
				  <p class="ans">亲爱的用户，你好</p>
				  <p class="ans">智能平板支持到1080P，需要确保观看的视频是高清视频源。</p>
			  </el-tab-pane>
			  <el-tab-pane label="声音问题">
				  <p class="que">问：为什么智慧黑板无声音输出？</p>
				  <p class="ans">尊敬的用户，您好，请确认：</p>
				  <p class="ans">1、若内置电脑无声音，首先检测喇叭是否正常，将装有歌曲的U盘插在TV板卡的USB口上，切换到多媒体通道下，测试有无声音，若有，则喇叭是好的，若无，可能是喇叭或者TV板卡的问题。</p>
				  <p class="ans">2、若喇叭是好的，一般是系统问题，请对系统进行重装系统操作。</p>
			  </el-tab-pane>
			  <el-tab-pane label="其他问题">
				  <p class="que">问：按键和遥控器功能是否一样？</p>
				  <p class="ans">亲爱的用户，你好</p>
				  <p class="ans">均能实现相应功能，当然遥控器支持更多操作。</p>
				  <p class="que">问：设备出现问题怎么办？</p>
				  <p class="ans">亲爱的用户，你好</p>
				  <p class="ans">尊敬的用户，您好,请按照我们提供的步骤排查，或者请拨打我们全国服务热线400-019-2795，会有专人为您解答。</p>
				  <p class="que">问：设备出现三无（指示灯不亮、无声音、屏幕没有光）故障怎么办？</p>
				  <p class="ans">亲爱的用户，你好请确认：</p>
				  <p class="ans">1.检查电源线是否连接好。</p>
				  <p class="ans">2.电源线插座供电是否正常。</p>
				  <p class="ans">3.设备电源开关是否开启。</p>
			  </el-tab-pane>
			</el-tabs>
		</div>
	</div>
</template>
<script>
	export default {
	   data(){
		   return{
				"on":true,
				tabPosition:'left'
			}
	   },
	   mounted(){
	
	  },
	   methods : {
	
		},
	}
</script>
<style scoped="scoped">
	.video_title{
		width: 100%;
		height: 30px;
		line-height: 30px;
		margin-top: 40px;
	}
	.line{
		width: 45%;
		height: 0px;
		border: 1px solid #909090;
		float: left;
		margin-top: 10px;
	}
	.name{
		float: left;
		width: 9%;
		text-align: center;
	}
	.card_content{
		width: 1000px;
		margin: 20px 0;
		text-align: center;
		left: 50%;
		margin-left: -500px;
		position: relative;
	}
	.is-active{
		color: #fff !important;
		background-color: #ed7c80 !important;
	}
	.que{
		font-size: 15px;
		color: #606060;
		text-align: left;
		padding:10px 0 0 10px;
	}
	.ans{
		font-size: 12px;
		color: #909090;
		text-align: left;
		text-align: left;
		padding: 2px 10px 0px 10px;
	}
	@media (max-width:767px) {
		.sever_text{
			text-align: center;
			color: #fff;
			width: 100%;
			font-weight: 600;
			font-size: 30px;
			margin-top:-35%;
			position: absolute;
		}
		.line{
			width: 34%;
			background-color: #000000;
		}
		.name{
			width: 30%;
			text-align: center;
		}
		.card_content{
			width: 100% ;
			margin: 0 ;
			left: 0;
			box-sizing: border-box;
		}
		.video_contaniner{
			width: 100%;
		}
	}
</style>
