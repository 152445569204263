<template>
	<div class="video">
		<div class="banner" v-if="this.isPc">
			<img src="static/server_banner.png"  width="100%"/>
		</div>
		<div class="banner" v-if="!this.isPc">
			<img src="static/ask_banner.png"  width="100%" />
			<p class="sever_text">服务中心</p>
		</div>
		<div class="video_title" >
			<div class="line"></div>
			<div class="name">视频中心</div>
			<div class="line"></div>
		</div>
		<div class="video_btn">
			<!-- <el-button class="buttom" v-on:click='on=true'>产品视频介绍</el-button> -->
			<!-- <el-button type="danger" v-on:click='on=false'>常见问题解决</el-button> -->
		</div>
		<div class="video_content" v-if="on">
			<div class="video_contaniner" v-for="(item,index) in videoList" :key="index">
				<video-player  class="video-player vjs-custom-skin" 
				  ref="videoPlayer"
				  :playsinline="true"
				  :options="playerOptions[index]"
				  @play="play(index)"
				></video-player>
				<!-- <div class="video_img"><img :src="httpsUrl+ item.goods_img"></div> -->
				<p class="video_little_name">{{item.goods_name}}</p>
				<p class="video_time">{{item.startTime}}</p>
			</div>
		</div>
		<div class="video_content" v-if="!on">
			<div class="video_contaniner">
				<div class="video_img"><img src="static/into_1.png"></div>
				<p class="video_little_name">AI智能黑板</p>
				<p class="video_time">1分20秒</p>
			</div>

			</div>
		</div>
	</div>
</template>
<script>
	export default {
	   data(){
		   return{
				"on":true,
				videoList:[],
				playerOptions:[],
				oldIndex:999,
			}
	   },
	   mounted(){
		var that = this;
			
		const url =  this.httpsUrl+'/api/index.php?act=product_video';
		            this.$http.get(url).then(
		                response => {

		                    console.log(response.body);
							this.videoList = response.body.list;
		                    // this.repositoryUrl = result.html_url;
		                    // this.repositoryName = result.name;
							for(var i=0;i<this.videoList.length;i++){
								var replaceAfter = JSON.stringify(this.videoList[i].mp4Url);
								var reg = /\\/g;
								var mp4 = replaceAfter.replace(reg,'');
								mp4 =mp4.substr(1,mp4.length-2)
								console.log(mp4)
								mp4 = JSON.parse(mp4);
								let arr ={
								    // playbackRates: [0.7, 1.0, 1.5, 2.0], //播放速度
								    autoplay: false, //如果true,浏览器准备好时开始回放。
								    muted: false, // 默认情况下将会消除任何音频。
								    loop: false, // 导致视频一结束就重新开始。
								    preload: 'auto', // 建议浏览器在<video>加载元素后是否应该开始下载视频数据。auto浏览器选择最佳行为,立即开始加载视频（如果浏览器支持）
								    language: 'zh-CN',
								    aspectRatio: '16:9', // 将播放器置于流畅模式，并在计算播放器的动态大小时使用该值。值应该代表一个比例 - 用冒号分隔的两个数字（例如"16:9"或"4:3"）
								    fluid: true, // 当true时，Video.js player将拥有流体大小。换句话说，它将按比例缩放以适应其容器。
								    sources: [{
								      type: "",//这里的种类支持很多种：基本视频格式、直播、流媒体等，具体可以参看git网址项目
								      src:  mp4[0].url,//url地址
								    }],
								    poster:this.httpsUrl+this.videoList[i].goods_img, //你的封面地址
								    // width: document.documentElement.clientWidth, //播放器宽度
								    notSupportedMessage: '此视频暂无法播放，请稍后再试', //允许覆盖Video.js无法播放媒体源时显示的默认信息。
								    controlBar: {
								      timeDivider: true,
								      durationDisplay: true,
								      remainingTimeDisplay: false,
								      fullscreenToggle: true  //全屏按钮
								    }
								}
								this.playerOptions.push(arr);
							}



		                },
		                response => {
		                  
		                },
		            );
	  },
	   methods : {
		   
		   play:function(index){
			   console.log(this.oldIndex)
			   
			   if(this.oldIndex !=999 && this.oldIndex !=index){
				   console.log(this.oldIndex )
				   this.$refs.videoPlayer[this.oldIndex].player.pause()
				  
			   }
			    this.oldIndex = index;
			 
		   }
		},
	}
</script>
<style scoped="scoped">
	.video_title{
		width: 100%;
		height: 30px;
		line-height: 30px;
		margin-top: 40px;
		clear: both;
	}
	.line{
		width: 45%;
		height: 0px;
		border: 1px solid #909090;
		float: left;
		margin-top: 10px;
	}
	.name{
		float: left;
		width: 9%;
		text-align: center;
	}
	.video_btn{
		text-align: center;
		padding-top: 50px;
	}
	.buttom{
		margin-right: 100px;
	}
	.video_content{
		width: 1000px;

		text-align: center;
		left: 50%;
		margin-left: -500px;
		position: relative;
		overflow: hidden;
		box-sizing: border-box;
	}
	.video_contaniner{
		width: 33%;
		padding: 10px;
		box-sizing: border-box;
		float: left;
		box-sizing: border-box;
	}
	.video_img{
		width: 100%;
		height: 150px;
	}
	.video_img img{
		width: 100%;
		height: 100%;
	}
	.video_little_name{
		text-align: left;
		padding: 5px;
		font-size: 14px;
		box-sizing: border-box;
	}
	.video_time{
		font-size: 12px;
		color: #909090;
		text-align: left;
		padding: 0 5px;
		box-sizing: border-box;
	}
	@media (max-width:767px) {
		.sever_text{
			text-align: center;
			color: #fff;
			width: 100%;
			font-weight: 600;
			font-size: 30px;
			margin-top:-35%;
			position: absolute;
		}
		.line{
			width: 34%;
			background-color: #000000;
		}
		.name{
			width: 30%;
			text-align: center;
		}
		.video_content{
			width: 100% ;
			margin: 0 ;
			left: 0;
			box-sizing: border-box;
		}
		.video_contaniner{
			width: 100%;
		}
	}
</style>
