<template>
  <div class="video">	
	<div class="banner" v-if="this.isPc">
	  <img :src="banner" width="100%"/>
	  <div class="btn_content">
		  <span class="btn" @click="toASK()">点击咨询</span>
		  <!-- <span class="btn" @click="toAskFor()">立即试用</span> -->
	  </div>
	</div>
	<div class="banner"  v-if="!this.isPc">
	  <img :src="banner" width="300%"  style="margin-left: -10%;"/>
	  <div class="btn_content">
	  		  <span class="btn" @click="toASK()">点击咨询</span>
	  		  <span class="btn" @click="toAskFor()">立即试用</span>
	  </div>
	  <!-- <p class="moblie_text">全部产品</p> -->
	</div>
	
    <div class="pro_content" v-if="this.isPc">
      <p class="video_title">产品介绍</p>
      <div class="into_content" v-for="(item,index) in list" :key='index' >
        <div v-if="index%2==0">
          <div class="into_left">
            <p class="into_title">{{ item.title }}</p>
            <p class="into_detail">{{ item.detail }}</p>
            <div class="bottom_line"></div>
          </div>
          <div class="into_right">
            <img :src="item.img"/>
          </div>
        </div>
        <div v-else>
          <div class="into_right">
            <img :src="item.img"/>
          </div>
          <div class="into_left">
            <p class="into_title">{{ item.title }}</p>
            <p class="into_detail">{{ item.detail }}</p>
            <div class="bottom_line"></div>
          </div>
        </div>
      </div>
	  <!-- 移动端info -->
    </div>
	<div class="pro_content" v-if="!this.isPc">
	  <p class="video_title">产品介绍</p>
	  <div class="into_content" v-for="(item,index) in list" :key='index' >
	   
	      <div class="into_right">
	        <img :src="item.img"/>
	      </div>
	      <div class="into_left">
	        <p class="into_title">{{ item.title }}</p>
	        <p class="into_detail">{{ item.detail }}</p>
	        <div class="bottom_line"></div>
	      </div>
	  </div>
	  <!-- 移动端info -->
	
	</div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      "banner": "static/pro_1/banner.png",
      "list": [{
        "img": 'static/pro_1/img1.png',
        "title": '超清显示,防眩光工艺再次升级',
        "detail": '4K超清显示大屏，更有柔和/动态等多种护眼模式可供选择。既让孩子看的清楚也让孩子看的舒适',
      },
        {
          "img": 'static/pro_1/img2.png',
          "title": '超大广角,坐在那里都毫无影响',
          "detail": '4K超清显示大屏，更有柔和/动态等多种护眼模式可供选择。既让孩子看的清楚也让孩子看的舒适',
        },
        {
          "img": 'static/pro_1/img3.png',
          "title": '镁铝合金，全新材料架构更安全',
          "detail": '全身采用镁铝合金制造，机型全身减重50%以上，真正做到更轻更安全。',
        },
        {
          "img": 'static/pro_1/img4.png',
          "title": 'AI摄像头，高清捕捉每一处细节',
          "detail": '全新的超清AI摄像头，可以跟随人像自由移动，保证老师上课的每一处细节都可以传授给学生。',
        },
      ],
      proData: [
        {
          "banner": 'static/pro_1/banner.png',
          "list": [{
            "img": 'static/pro_1/img1.png',
            "title": '超清显示,防眩光工艺再次升级',
            "detail": '4K超清显示大屏，更有柔和/动态等多种护眼模式可供选择。既让孩子看的清楚也让孩子看的舒适',
          },
            {
              "img": 'static/pro_1/img2.png',
              "title": '超大广角,坐在那里都毫无影响',
              "detail": '4K超清显示大屏，更有柔和/动态等多种护眼模式可供选择。既让孩子看的清楚也让孩子看的舒适',
            },
            {
              "img": 'static/pro_1/img3.png',
              "title": '镁铝合金，全新材料架构更安全',
              "detail": '全身采用镁铝合金制造，机型全身减重50%以上，真正做到更轻更安全。',
            },
            {
              "img": 'static/pro_1/img4.png',
              "title": 'AI摄像头，高清捕捉每一处细节',
              "detail": '全新的超清AI摄像头，可以跟随人像自由移动，保证老师上课的每一处细节都可以传授给学生。',
            },
          ],
        },
        {
          "banner": 'static/pro_2/banner.png',
          "list": [{
            "img": 'static/pro_2/img1.png',
            "title": '人脸识别，AI智能扫描确认健康',
            "detail": '采用国际领先的人脸识别算法技术检测人脸，红外扫描温度确认精准',
          },
            {
              "img": 'static/pro_2/img2.png',
              "title": '状态多样，支持所有的工作场景',
              "detail": '拥有多种安装模式，可以支持任何工作场景，学校公司都可以完美适配。',
            },
          ],
        },
        {
          "banner": 'static/pro_3/banner.png',
          "list": [{
            "img": 'static/pro_3/img1.png',
            "title": '一键录音，实时传输无时间限制',
            "detail": '功能更多支持单选、判断、互动游戏，更有一键录音功能，录音实时上传，无时长限制。',
          },
            {
              "img": 'static/pro_3/img2.png',
              "title": '按键简洁，点阵屏设计更易操作',
              "detail": '采用2.4G封闭式安全无线网，通讯距离可达15m，最多可以30位同学同时使用',
            },
            {
              "img": 'static/pro_3/img3.png',
              "title": '网络流畅，支持30个学生同时用',
              "detail": '拥有多种安装模式，可以支持任何工作场景，学校公司都可以完美适配。',
            },
            {
              "img": 'static/pro_3/img4.png',
              "title": '蓄电力强，待机时间长充电简单',
              "detail": '采用1200mAh可充电锂电池，满电情况下可用3-5天，待机时长30天。接收器自带充电功能，答题器插入即充。',
            },
          ],
        },
        {
          "banner": 'static/pro_4/banner.png',
          "list": [{
            "img": 'static/pro_4/img1.png',
            "title": '拒绝污染，让生活和学习更环保',
            "detail": '极大程度的降低了隶污染、辐射污染、光污染、噪音污染等问题，更加环保健康。',
          },
            {
              "img": 'static/pro_4/img2.png',
              "title": '显色度高，不放过任何一个角落',
              "detail": '显色指数高达95%，无拼接，不会有暗场，灯光颜色均匀，照明更出色。',
            },
            {
              "img": 'static/pro_4/img3.png',
              "title": '安全性高，不用再担心灯杆脱落',
              "detail": '选用了传统灯无灯杆设计，可以有效防止灯杆脱落问题，减少风险。',
            },
            {
              "img": 'static/pro_4/img4.png',
              "title": '智能控制，灯光场景可任意调节',
              "detail": '将灯具智能化，实现了自动调节灯光、场景的设置、更重要的是可远程控制。',
            },
          ],
        },
        {
          "banner": 'static/pro_5/banner.png',
          "list": [{
            "img": 'static/pro_5/img1.png',
            "title": '多种形状，自由组合可随意调节。',
            "detail": '桌面具有两种优质材料可供选择，桌架结实耐用，组合性桌椅，具有直角、圆弧、梯形等形状可供调节。',
          },
            {
              "img": 'static/pro_5/img2.png',
              "title": '实用美观，适用于小组讨论模式',
              "detail": '桌椅加上一体机或电视机，既适合小组讨论又便于师生互动，布局整洁大方又具有实用性。',
            },
            {
              "img": 'static/pro_5/img3.png',
              "title": '贴合人体，多功能设计更加便利',
              "detail": 'S型超大靠背更加舒适，材质结实，承重113.4kg，360°可旋转桌板，配有笔槽、杯架及书栏更实用。',
            },
            {
              "img": 'static/pro_5/img4.png',
              "title": 'T型设计，造型美观且性能极佳',
              "detail": '显示器角度可调整，内置实时拍照的无线投屏器和高拍仪，桌面高度可调，自带移动电源和音响。',
            },
            {
              "img": 'static/pro_5/img5.png',
              "title": '应有尽有，方便上课的各种需要',
              "detail": '材质结实，内置触控显示器、高拍仪、中控、鹅颈麦等，显示器可调节，适合多种教学需求。',
            },
            {
              "img": 'static/pro_5/img4.png',
              "title": '简洁舒适，款式大方颜色可选择',
              "detail": '电动调节高度、过点、过温保护，预留了放置物品的空间，采用优质材料，既美观又实用。',
            },
          ],
        },
        {
          "banner": 'static/pro_6/banner.png',
          "list": [{
            "img": 'static/pro_6/img1.png',
            "title": '极光配色，颜值与实力并存之作',
            "detail": '潮流炫酷的配色，让学生学习更显酷炫。',
          },
            {
              "img": 'static/pro_6/img2.png',
              "title": '高效互动，学生想法一步就到位',
              "detail": '传统教学模式的延伸，讲师在讲台上就可以一步看到所有学生的板书作业！',
            },
            {
              "img": 'static/pro_6/img3.png',
              "title": '环保材料，设计细节环保无污染。',
              "detail": '全身采用环保材料制作，整体减重一半以上，真正做到更轻更安全。',
            },
            {
              "img": 'static/pro_6/img4.png',
              "title": 'AI摄像头，学生思维老师看仔细',
              "detail": '全新的超清AI摄像头，可以跟随学生笔迹进行记录，从而让学生每一步做题思路老师都看的明确。',
            },
          ],
        },
        {
          "banner": 'static/pro_7/banner.png',
          "list": [{
            "img": 'static/pro_7/img1.png',
            "title": '全新外观，涡轮蜗杆传动无死角',
            "detail": '全新设计的外观，造型迷你别致，共有两种颜色。采用蜗轮蜗杆传动设计，无死角，更方便。',
          },
            {
              "img": 'static/pro_7/img2.png',
              "title": '超大广角，搭配光学变焦更优秀',
              "detail": '采用72.5°的高品质广角镜头，让视野更开阔。光学变焦可达12倍并支持16倍数字变焦，让图像更清晰。',
            },
            {
              "img": 'static/pro_7/img3.png',
              "title": '支持3G-SDI，满足你多样化的需求',
              "detail": '3G-SDI最大可支持传输1080p60无压缩原始视频信号，并能够向下兼容HD-SDI主流格式。',
            },
          ],
        },
        {
          "banner": 'static/pro_8/banner.png',
          "list": [{
            "img": 'static/pro_8/img1.png',
            "title": '文化宣传，呈现丰富多彩的校园',
            "detail": '展现多姿多彩的校园生活，随时了解班级情况。支持在线、定向、定时发布消息。',
          },
            {
              "img": 'static/pro_8/img2.png',
              "title": '实时推送，更便捷参与活动报名',
              "detail": '班牌实时推送活动，学生通过卡片识别认证身份，参与活动报名。',
            },
            {
              "img": 'static/pro_8/img3.png',
              "title": '智能考勤，同步云端自动生成表',
              "detail": '学生通过刷卡与人脸识别进行考勤，自动提醒家长。数据实时同步云端并生成考勤表。',
            },
            {
              "img": 'static/pro_8/img4.png',
              "title": '动态课表，实时展示出课程信息',
              "detail": '能够代替传统的纸质课表，实时动态展示课程信息，让学生更及时的获取课程名称和信息。',
            },
          ],
        },
        {
          "banner": 'static/pro_9/banner.png',
          "list": [{
            "img": 'static/pro_9/img1.png',
            "title": '杜比音效，环绕动听身临其境',
            "detail": '六道环绕杜比音效，极致视听盛宴，360度环绕立体声，告别杂音。',
          },
            {
              "img": 'static/pro_9/img2.png',
              "title": '网络电容，准确表达坚决不出错',
              "detail": '采用金属网格电容感应系统，可有效防止误触，从而达到更准确的使用效果。',
            },
            {
              "img": 'static/pro_9/img3.png',
              "title": '流畅书写，让表达再不复杂',
              "detail": 'window原笔迹书写，课堂板书，会议笔记等可通用重复编辑，手势擦除，移动，缩放，让书写更简单。',
            },
            {
              "img": 'static/pro_9/img4.png',
              "title": '无线传屏，大屏小屏随心链接',
              "detail": '手机电脑，可同时实时数据同屏，告别各式数据线随时同屏，风险更加自由，授课开会全新体验。',
            },
          ],
        },
        {
          "banner": 'static/pro_10/banner.png',
          "list": [{
            "img": 'static/pro_10/img1.png',
            "title": '效果爆炸，环绕拾音应用场景丰富',
            "detail": '应用于企业级会议的理想之选，适合在中小型会议室和办公室使用。360度范围3米的拾音距离涵盖了绝大部分会议应有场景。',
          },
            {
              "img": 'static/pro_10/img2.png',
              "title": '外界音响，解放双手更加自如',
              "detail": '内置锂电池，省能省电，简易USB充电，可以连接耳机，有源音箱。',
            },
          ],
        },
      ]
    }
  },
  watch: {
    '$route': 'getId'// 值可以为methods的方法名
  },
  mounted() {
    this.getId();
  },
  methods: {
    toASK: function () {
      this.$router.push('/server_2')
    },
	toAskFor: function () {
	  this.$router.push('/ask')
	},
    getId: function () {
      const i = this.$route.query.num;
      this.list = this.proData[i].list;
      this.banner = this.proData[i].banner;
      window.scrollTo(0, 0);
    }
  },
}
</script>
<style scoped="scoped">
.video_title {
  width: 100%;
  height: 30px;
  font-size: 25px;
  font-weight: 600;
  margin-bottom: 30px;
}

.pro_content {
  width: 1000px;
  margin: 20px 0;
  text-align: center;
  left: 50%;
  margin-left: -500px;
  position: relative;
  box-sizing: border-box;
  overflow: hidden;

}

.into_content {
  margin-top: 30px;
  overflow: hidden;
  height: 250px;
  margin-top: 30px;
}

.banner {
  cursor: pointer;
  position: relative;
  width: 100%;
  overflow-x: hidden;
}
.btn_content{
	position: absolute;
	bottom: 30%;
	left: 6%;
}
.btn_content .btn{
	color: #fff;
	margin-right: 15px;
	background-color: #303030;
	padding: 12px 30px;
	font-size: 13px;
	border-radius: 25px;
	
}
.into_left {
  width: 500px;
  float: left;
  padding: 0 100px;
  box-sizing: border-box;
  text-align: left;
  height: 225px;
  position: relative;

}

.into_right {
  width: 500px;
  padding: 0 100px;
  box-sizing: border-box;
  float: left;

}

.bottom_line {
  width: 150px;
  border-bottom: 1px solid #606060;
  position: absolute;
  bottom: 0;
  left: 50%;
  margin-left: -75px;
}

.into_right img {
  width: 100%;
  height: 100%;
}

.into_title {
  margin-bottom: 20px;
  font-size: 18px;
  font-weight: 600;
}

.into_detail {
  font-size: 14px;
}
/* 移动端css */
@media (max-width:767px) {
	.video_title{
		margin-top: 20px;
	}
	.pro_content{
		width: 100% ;
		margin: 0 ;
		left: 0;
		box-sizing: border-box;
	}
	.into_left{
		width: 100%;
		padding: 30px;
		float: none;
		clear: both;
		height: auto;
		
	}
	.into_content{
		height: auto;
	}
	.btn_content{
		position: absolute;
		bottom: 30% ;
		left: 8%;
	}
	.btn_content .btn{
		color: #fff;
		margin-right: 5px;
		background-color: #303030;
		padding: 10px 20px;
		font-size: 12px;
		border-radius: 25px;
		
	}
	.into_right{
		border-top: 1px solid #EFEFEF;
		width: 100%;
		padding: 0;
		float: none;
		clear: both;
	}
}
</style>
