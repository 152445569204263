<template>
  <div>
    <div class="banner" v-if="this.isPc">
      <img src="static/about_banner.png" width="100%"/>
    </div>
	<div class="banner"  v-if="!this.isPc">
		<img src="static/moblie_banner.png" width="100%"/>
	</div>
    <div class="company_into">
      <div class="into_left">
        我们是谁
        <div class="into_line"></div>
      </div>
      <div class="into_right">
        <p>九五云在线教育品牌创立于2018年，总部位于上海。致力于
          服务培训机构、私立和公立学校通过智慧校园和空中课堂实现双师及互动
          直播，结成名师大班制的BTBTC教学联盟，从而将各方资源链接起来，打
          造成为多方诚信合作、共享共赢的教育事业生态链。公司通过自主研发，
          实现了将AI硬件、云软件和优质名师相接合的空中课堂部署方案，降低了
          在线教育综合成本。 公司已获得多项专利技术证书，属于国家高新技术产
          业公司。</p>
        <p style="margin: 20px 0;">我们的愿景是：“同蓝天，九五云，人人得到名师教学</p>
        <div class="videoContent">
          <video-player class="video-player vjs-custom-skin"
                        ref="videoPlayer"
                        :playsinline="true"
                        :options="playerOptions"
          ></video-player>
        </div>
      </div>

    </div>
    <div class="mid_banner">
      <img src="static/furtue.png" width="100%" v-if="this.isPc"/>
	  <img src="static/furtue.png" width="200%" style="margin-left: -50%;" v-if="!this.isPc"/>
    </div>
    <div class="about_menu">
      <el-menu :default-active="activeIndex" type="flex" justify='center' class="el-menu-demo  about_me"
               mode="horizontal" @select="handleSelect">
        <el-menu-item class="item" index="10">公司历程</el-menu-item>
        <el-menu-item class="item" index="11">公司理念</el-menu-item>
        <el-menu-item class="item" index="12">公司公益</el-menu-item>
      </el-menu>

    </div>

    <div v-if="item_one">
      <img src="static/company_his.png" width="100%" style="margin-top: 20px;"/>
    </div>
    <div class="item_content" v-if="item_two">
      <div class="item_left">
        <p class="item_title">同蓝天，九五云,</p>
        <p class="item_title" style="margin-bottom: 40px;">人人可以得到名师教学</p>
        <p>九五尊易自成立之初就定下了一个“小目标”</p>
        <p>要让不管在哪里的孩子</p>
        <p>都可以得到同样的名师教学</p>
        <p>我们优选了各大985 211高校的优秀教师</p>
        <p>整合了世界各地的优秀教学资源</p>
        <p>来为各个角落的孩子服务</p>
        <p>九五有勇气、有决心、有毅力来完成这件事情</p>
        <p>把每一份精力都投入到教学中去</p>
        <p>把每一份经验都全部的传递给孩子们</p>
        <p>这就是我们九五人始终践行的理论</p>
      </div>
      <div class="item_right">
        <img src="static/item_two.png"/>
      </div>
    </div>
    <div class="item_content" v-if="item_three">
      <div class="item_left">
        <p class="item_title">肩膀计划</p>
        <p>有一种感动叫难过，亿万孩子同在蓝天下，</p>
        <p>看得的却不是同一片蓝天。</p>
        <p>有一种画面不是美，双眸忧郁而迷惑，</p>
        <p>企盼一个肩膀撑起春天的希望。</p>
        <p>努力爬上一堵高墙，摘下一颗星星。</p>
        <p>孩子的眼中，是望眼欲穿的渴望。</p>
        <p>老师与贫困地区的孩子间有道屏障。</p>
        <p>如何打破，让教育真正实现零距离？</p>
      </div>
      <div class="item_right">
        <img src="static/item_three.png"/>
      </div>
    </div>
    <!-- 联系我们 -->
    <div class="map_content item_content">
      <p class="map_title">联系我们</p>
      <div class="item_left">
        <baidu-map class="map" style="display: flex;" :scroll-wheel-zoom='true' :zoom="15"
                   :center="detail.centername">
          <bm-view style="width: 80%; height:250px; flex: 1"></bm-view>
        </baidu-map>
      </div>
      <div class="item_right map_right">
        <p>总部：<span @click="changeMap(0)">上海</span></p>
        <p>分部：<span @click="changeMap(1)">苏州</span><span @click="changeMap(2)">达州</span>
          <span @click="changeMap(3)">北京</span>

        <div class="map_detial">
          <div class="detail_name">{{ detail.detail_name }}</div>
          <div class="detail_into">地址：{{ detail.detail_adress }}</div>
          <div class="detail_into">电话：{{ detail.detail_phone }}</div>
        </div>
      </div>

    </div>
  </div>
</template>

<script>

export default {
  data () {
    return {
      playerOptions: {
        playbackRates: [0.7, 1.0, 1.5, 2.0], // 播放速度
        autoplay: false, // 如果true,浏览器准备好时开始回放。
        muted: false, // 默认情况下将会消除任何音频。
        loop: false, // 导致视频一结束就重新开始。
        preload: 'auto', // 建议浏览器在<video>加载元素后是否应该开始下载视频数据。auto浏览器选择最佳行为,立即开始加载视频（如果浏览器支持）
        language: 'zh-CN',
        aspectRatio: '16:9', // 将播放器置于流畅模式，并在计算播放器的动态大小时使用该值。值应该代表一个比例 - 用冒号分隔的两个数字（例如"16:9"或"4:3"）
        fluid: true, // 当true时，Video.js player将拥有流体大小。换句话说，它将按比例缩放以适应其容器。
        sources: [{
          type: '', // 这里的种类支持很多种：基本视频格式、直播、流媒体等，具体可以参看git网址项目
          src: 'https://oss-prd.oss-cn-hangzhou.aliyuncs.com/95isee/1/4829/5b06c2b4-56d9-41af-a56e-3f9b67524ae6.mp4' // url地址
        }],
        poster: 'static/videoPost.jpg', // 你的封面地址
        // width: document.documentElement.clientWidth, //播放器宽度
        notSupportedMessage: '此视频暂无法播放，请稍后再试', // 允许覆盖Video.js无法播放媒体源时显示的默认信息。
        controlBar: {
          timeDivider: true,
          durationDisplay: true,
          remainingTimeDisplay: false,
          fullscreenToggle: true // 全屏按钮
        }
      },
      'on': true,
      videoSrc: 'https://oss-prd.oss-cn-hangzhou.aliyuncs.com/95isee/1/4829/5b06c2b4-56d9-41af-a56e-3f9b67524ae6.mp4',
      videoImg: 'http://static.fdc.com.cn/avatar/usercenter/5996999fa093c04d4b4dbaf1_162.jpg',
      playStatus: '',
      muteStatus: '',
      isMute: true,
      isPlay: false,
      item_one: true,
      item_two: false,
      item_three: false,
      activeIndex: '10',
      detail: {
        detail_name: '上海',
        centername: '上海闵行区莘建东路58弄',
        detail_adress: '上海闵行区莘建东路58弄绿地科技岛A楼2207室',
        detail_phone: '021-80370595',
        detail_email: '95@95isee.com'
      },
      adress: [
        {
          detail_name: '上海',
          centername: '上海闵行区莘建东路58弄',
          detail_adress: '上海闵行区莘建东路58弄绿地科技岛A楼2207室',
          detail_phone: '021-80370595',
          detail_email: '95@95isee.com'

        },
        {
          detail_name: '苏州',
          centername: '江苏省苏州市科技城道元路18号417',
          detail_adress: '江苏省苏州市科技城道元路18号417',
          detail_phone: '+86 512 87813389',
          detail_email: '95@95isee.com'
        },
        {
          detail_name: '达州',
          centername: '达州市达川区南外镇康乐巷391号',
          detail_adress: '达州市达川区南外镇康乐巷391号',
          detail_phone: '+86 13350777676',
          detail_email: '95@95isee.com'
        },
        {
          detail_name: '北京',
          centername: '北京市朝阳区望京东路8号院锐创国际B座',
          detail_adress: '北京市朝阳区望京东路8号院锐创国际B座1219室',
          detail_phone: '+86 13910717691',
          detail_email: '95@95isee.com'
        },
        {
          detail_name: '武汉',
          centername: '湖北省武汉市洪山区中建福地星城',
          detail_adress: '湖北省武汉市洪山区中建福地星城2栋1单元3301室',
          detail_phone: '+86 18605122848 ',
          detail_email: '95@95isee.com'
        },
        {
          detail_name: '成都',
          centername: '四川省成都市金牛区蜀西路9号丰德羊西中心1005室',
          detail_adress: '四川省成都市金牛区蜀西路9号丰德羊西中心1005室',
          detail_phone: '+86 15508226666',
          detail_email: '95@95isee.com'
        },
        {
          detail_name: '广州',
          centername: '广东省广州市凤凰城凤馨苑5街8座1804室',
          detail_adress: '广东省广州市凤凰城凤馨苑5街8座1804室',
          detail_phone: '+86 18626111222',
          detail_email: '95@95isee.com'
        },
        {
          detail_name: '淮安',
          centername: '江苏省淮安市淮安区河下竹巷街小板跳巷2号',
          detail_adress: '江苏省淮安市淮安区河下竹巷街小板跳巷2号',
          detail_phone: '+86 13505230662',
          detail_email: '95@95isee.com'
        },
        {
          detail_name: '镇江',
          centername: '镇江丹阳市丹阳高新技术创业园齐梁路19号',
          detail_adress: '镇江丹阳市丹阳高新技术创业园齐梁路19号H1座东面平层丹阳市旅游协会',
          detail_phone: '+86 15952921069',
          detail_email: '95@95isee.com'
        }
      ]

    }
  },
  mounted () {

  },
  methods: {
    handleSelect (key, keyPath) {
      switch (key) {
        case '10':
          this.item_one = true
          this.item_two = false
          this.item_three = false
          break
        case '11':
          this.item_one = false
          this.item_two = true
          this.item_three = false
          break
        case '12':
          this.item_one = false
          this.item_two = false
          this.item_three = true
          break
      }
    },
    changeMap (index) {
      this.detail = this.adress[index]
    }
  }
}
</script>

<style scoped="scoped">

.company_into {
  width: 1000px;
  margin: 20px 0;
  text-align: center;
  left: 50%;
  margin-left: -500px;
  position: relative;
  overflow: hidden;
}

.into_left {
  width: 400px;
  float: left;
  text-align: left;
  font-size: 30px;
  font-weight: 550;
  position: relative;
}

.into_line {
  border: 2px solid #DF2228;
  width: 10%;
}

.into_right {
  width: 600px;
  float: left;
  text-align: left;
  text-indent: 30px;
  font-size: 14px;
  color: #909090;
  line-height: 25px;
}

.about_menu {
  width: 600px;
  text-align: center;
  left: 50%;
  margin-left: -300px;
  position: relative;
  overflow: hidden;
  margin-top: -70px;
  box-shadow: 0px 0px 15px #E0E0E0

}

.about_me {
  margin-right: 0 !important;
  border-bottom: solid 1px #e6e6e6 !important;

}

.about_me .item {
  margin: 10px 51px;

}

.item_content {
  width: 1000px;
  margin: 20px 0;
  text-align: center;
  left: 50%;
  margin-left: -500px;
  position: relative;
  padding-top: 30px;
  overflow: hidden;
}

.item_title {
  font-size: 25px;
  font-weight: 550;
}

.item_left {
  width: 500px;
  float: left;
  text-align: left;
  text-indent: 30px;
  font-size: 14px;
  color: #000;
  line-height: 25px;
}

.item_left p {
  line-height: 35px;
}

.item_right {
  width: 470px;

  float: right;
  text-align: left;
}

.item_right img {
  width: 100%;
}

.map_title {
  font-size: 25px;
  font-weight: 550;
  margin-bottom: 30px;
}

.map_right {
  color: #606060;
  position: relative;
  height: 250px;
}

.map_right span {
  margin: 10px 20px 0 0;
  padding: 5px 0;
}

.map_right p {
  margin-bottom: 15px;
}

.map_active {
  border-bottom: 1px solid red;
  color: red;
}

.map_detial {
  position: absolute;
  bottom: 0;
  border-top: 2px solid #606060;
  padding: 10px 0 0 0;
}

.detail_name {
  font-size: 20px;
  color: #FF0000;
}

.detail_into {
  margin-top: 10px;
  font-size: 13px;
  color: #606060;
}

.videoContent {
  width: 100%;
  box-sizing: border-box;
  float: left;
  box-sizing: border-box;
  text-indent: 0;
}

.map_right span {
  cursor: pointer;
}

.map_right span:hover {
  text-decoration: underline;
}
@media (max-width:767px) {
		.line{
			width: 34%;
			background-color: #000000;
		}
		.name{
			width: 30%;
			text-align: center;
		}
		.company_into{
			width: 100% ;
			margin: 0 ;
			left: 0;
			padding: 10px;
			box-sizing: border-box;
		}
		.video_contaniner{
			width: 100%;
		}
		.into_left{
			width: 100%;
		}
		.into_right{
			width: 100%;
		}
		.about_menu{
			width: 100%;
			margin: 0 ;
			left: 0;
			/* padding: 10px; */
			box-sizing: border-box;
		}
		.about_me .item{
			    margin: 10px 0;
				width: 33.3%;
		}
		.item_content{
			width: 100%;
			margin: 0 ;
			left: 0;
			/* padding: 10px; */
			box-sizing: border-box;
		}
		/* .item_left{
			width: 100%;
			padding: 10px;
			box-sizing: border-box;
		} */
		.item_right{
			width: 100%;
			padding: 10px;
			box-sizing: border-box;
		}
		.map_detial{
			width: 95%;
			padding: 10px;
			box-sizing: border-box;
		}
	}
</style>
