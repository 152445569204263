<template>
    <video-player class="video-player vjs-custom-skin"
                  ref="videoPlayer"
                  :playsinline="true"
                  :options="playerOptions"
    ></video-player>
</template>

<script>
export default {
  data () {
    return {
      playerOptions: {

      }
    }
  },
  mounted () {
    this.playerOptions = {
      sources: [{
        type: '',
        src: 'https://cdn.95isee.cn/95isee/1/4829/ddaa92aa-965f-4d2f-af4a-4f36afb92df8.mp4'
      }],
      poster: 'static/videoPost.jpg', // 你的封面地址
      // width: document.documentElement.clientWidth, // 播放器宽度
      notSupportedMessage: '此视频暂无法播放，请稍后再试' // 允许覆盖Video.js无法播放媒体源时显示的默认信息。
    }
  },
  methods: {
  }
}
</script>
<style>
</style>
