<template>
  <div>
    <div class="banner" v-if="this.isPc">
      <img src="static/pro_banner.png" width="100%"/>
	  <div class="btn_content">
		  <span class="btn"  @click="toASK()">点击咨询</span>
		  <!-- <span class="btn">立即试用</span> -->
	  </div>
    </div>
	<div class="banner"  v-if="!this.isPc">
	  <img src="static/moblie_probanner.jpg" width="100%" @click="toASK()"/>
	  <!-- <p class="moblie_text">全部产品</p> -->
	</div>
	<!-- 移动端商品展示 -->
	<!-- <div v-if="!this.isPc" class="all_pro">
		<div></div>
	</div> -->
    <div class="scoll_content" v-if="this.isPc" >
      <div class="scoll_left" @click="goRight()">
        <span><i class="el-icon-arrow-left"></i><span class="icon-name"></span></span>
      </div>
      <div class="scoll_mian">
        <div class="content" ref='width'>
          <div class="scoll_div" v-for="(item,index) in arr" :key="index" @click="toDetail(index)">
            <img class="item_img" :src="item.img"/>
            <p class="item_name">{{ item.name }}</p>
          </div>
        </div>
      </div>
      <div class="scoll_right" @click="goLeft()">
        <span><i class="el-icon-arrow-right"></i><span class="icon-name"></span></span>
      </div>
    </div>
    <div class="info_content" >
      <el-row :gutter="20">
        <el-col :span="moblie_big">
          <div class="grid-content bg-purple">
            <img src="static/into_101.png"/>
          </div>
          <p class="into_title">九五尊易智慧纳米黑板</p>
          <p class="into">智慧黑板，传统教育与科技现代的有效结合</p>
          <p class="into">采用全新科技制作，180°观看无死角</p>
          <p class="into">多媒体教学学生上课不在无趣</p>
          <p class="into_more" @click="toDetail(0)">了解更多</p>
        </el-col>
        <el-col :span="moblie_small">
          <div class="grid-content bg-purple">
            <img src="static/into_8.png"/>
          </div>
          <p class="into_title">九五尊易智慧云班牌</p>
          <p class="into">智能班牌，家校互通</p>
          <p class="into">家长第一时间了解孩子近况</p>
          <p class="into">老师掌握学生信息</p>
          <p class="into_more" @click="toDetail(7)">了解更多</p>
        </el-col>
        <el-col :span="moblie_small">
          <div class="grid-content bg-purple">
            <img src="static/into_7.png"/>
          </div>
          <p class="into_title">九五尊易AI千里眼</p>
          <p class="into">内嵌麦克风一机两用</p>
          <p class="into">2k 4k摄像头高清观看</p>
          <p class="into">上下调节寻找最佳视角</p>
          <p class="into_more" @click="toDetail(6)">了解更多</p>
        </el-col>
      </el-row>
      <el-row :gutter="20">
        <el-col :span="moblie_small">
          <div class="grid-content bg-purple">
            <img src="static/into_10.png" style="width: 80px;height: auto;padding: 40px;"/>
          </div>
          <p class="into_title">九五尊易AI顺风耳</p>
          <p class="into">顺风全收，再远也是面对面</p>
          <p class="into">操作简单，体积轻小</p>
          <p class="into">外接音箱，收放自如</p>
          <p class="into_more" @click="toDetail(9)">了解更多</p>
        </el-col>
        <el-col :span="moblie_small">
          <div class="grid-content bg-purple">
            <img src="static/into_3.png"/>
          </div>
          <p class="into_title">双师语音答题器</p>
          <p class="into">一键录音，实时传输</p>
          <p class="into">网络流畅，操作简洁</p>
          <p class="into">蓄电力强，超长待机</p>
          <p class="into_more" @click="toDetail(2)">了解更多</p>
        </el-col>
        <el-col :span="moblie_big">
          <div class="grid-content bg-purple">
            <img src="static/into_9.png"/>
          </div>
          <p class="into_title">九五尊易智慧纳米一体机</p>
          <p class="into">杜比音效，环绕动听身临其境</p>
          <p class="into">网络电容，准确表达</p>
          <p class="into">无线传屏，随心连接</p>
          <p class="into_more" @click="toDetail(8)">了解更多</p>
        </el-col>
      </el-row>
      <el-row :gutter="20">
        <el-col :span="moblie_small">
          <div class="grid-content bg-purple">
            <img src="static/into_4.png"/>
          </div>
          <p class="into_title">九五尊易教室护眼灯</p>
          <p class="into">显色度高，色域广阔</p>
          <p class="into">智能控制，灯光场景可任意调节</p>
          <p class="into">颜色均匀，照明更出色</p>
          <p class="into_more" @click="toDetail(3)">了解更多</p>
        </el-col>
        <el-col :span="moblie_small">
          <div class="grid-content bg-purple">
            <img src="static/into_5.png"/>
          </div>
          <p class="into_title">智慧教室组合座椅</p>
          <p class="into">实用美观，适用于小组讨论</p>
          <p class="into">多种形状，自由组合</p>
          <p class="into">贴合人体，更加便利</p>
          <p class="into_more" @click="toDetail(4)">了解更多</p>
        </el-col>
        <el-col :span="moblie_small">
          <div class="grid-content bg-purple">
            <img src="static/into_6.png"/>
          </div>
          <p class="into_title">九五尊易链阵笔</p>
          <p class="into">高效互动，一步到位</p>
          <p class="into">环保材料精细无污染</p>
          <p class="into">AI摄像头，深度互动</p>
          <p class="into_more" @click="toDetail(5)">了解更多</p>
        </el-col>
        <el-col :span="moblie_small">
          <div class="grid-content bg-purple">
            <img src="static/into_2.png"/>
          </div>
          <p class="into_title">九五尊易AI智能人脸识别</p>
          <p class="into">人脸识别，方便快捷</p>
          <p class="into">安装灵活适合各种场景</p>
          <p class="into">自动测温，确认健康</p>
          <p class="into_more" @click="toDetail(1)">了解更多</p>
        </el-col>

      </el-row>
    </div>
  </div>
</template>
<script>

export default {
  data() {
    return {
      bannerHeight: "",
	  moblie_small:6,
	  moblie_big:12,
      arr: [
        {
          img: "static/into_1.png",
          name: '智慧黑板'
        },
        {
          img: "static/into_2.png",
          name: '人脸识别'
        },
        {
          img: "static/into_3.png",
          name: '答题器'
        },
        {
          img: "static/into_4.png",
          name: '教室灯'
        },
        {
          img: "static/into_5.png",
          name: '教室套装'
        },
        {
          img: "static/into_6.png",
          name: '链阵笔'
        },
        {
          img: "static/into_7.png",
          name: 'AI千里眼'
        },
        {
          img: "static/into_8.png",
          name: '云班牌'
        },
        {
          img: "static/into_9.png",
          name: '一体机'
        },
        {
          img: "static/into_10.png",
          name: 'AI随风耳'
        },
      ],
      BannerImg: [
        "static/blackborde.png",
        "static/blackborde.png"
      ],
    }
  },
  mounted() {
	   window.scrollTo(0, 0);
	  if(!this.isPc){
	  	this.moblie_big=24;
	  	this.moblie_small=24;
	  }else{
		   this.$refs.width.style.width = this.arr.length * 120 + 'px';
	  }
	  
   
	
  },
  methods: {
    goLeft: function () {

      this.$refs.width.style.left = '-300px'
      let left = this.$refs.width.style.left;
      left = JSON.parse(left.split('px')[0]);
      let width = this.arr.length * 120
      if (width - 900 + left > 300) {
        this.$refs.width.style.left = left - 300
      } else if (width - 900 + left > 0) {
        this.$refs.width.style.left = left - width + 900 - left;
      }
    },
    goRight: function () {
      let left = this.$refs.width.style.left;
      left = JSON.parse(left.split('px')[0]);
      let width = this.arr.length * 120
      if (left < -300) {
        this.$refs.width.style.left = left + 300;
      } else if (left < 0) {
        this.$refs.width.style.left = 0;
      }
    },
    toDetail: function (index) {
      this.$router.push({path: "/template", query: {num: index}})
    },
    toASK: function () {
      this.$router.push('/server_2')
    }
  }
}
</script>

<style scoped="scoped">
.scoll_content {
  width: 1000px;
  margin: 20px 0;
  height: 100px;
  text-align: center;
  left: 50%;
  margin-left: -500px;
  position: relative;
}

.scoll_left {
  width: 50px;
  height: 100%;
  float: left;
  line-height: 100px;
}

.banner {
  cursor: pointer;
  position: relative;
}

.btn_content{
	position: absolute;
	bottom: 30%;
	left: 6%;
}
.btn_content .btn{
	color: #fff;
	margin-right: 15px;
	background-color: #303030;
	padding: 12px 30px;
	font-size: 13px;
	border-radius: 25px;
	
}

.scoll_right {
  width: 50px;
  height: 100%;
  line-height: 100px;
  float: right;
  cursor: pointer;
}

.scoll_div {
  width: 100px;
  height: 100px;
  box-sizing: border-box;
  cursor: pointer;
  float: left;
  margin: 0 10px;
}

.item_name {
  font-size: 12px;
  color: #909090;
  margin-top: 10px;
}

.item_img {
  margin-top: 15px;
  width: auto;
  height: 52px;
  max-width: 70px;
}

.scoll_mian {
  overflow-x: scroll;
  width: 900px;
  height: 100px;
  float: left;
  overflow-y: hidden;
  position: relative;
}

.content {
  width: 1500px;
  height: 100%;
  position: absolute;
  left: 0;
}

.info_content {
  width: 1000px;
  margin: 20px 0;
  left: 50%;
  margin-left: -500px;
  position: relative;
}

.info_line {
  width: 30px;
  border: 2px solid #df2228;
  border-radius: 5px;
  position: relative;
  left: 50%;
  margin-left: -15px;
}

/* 信息布局 */
.el-row {
  margin-top: 20px;
  max-width: 1200px;

&
:last-child {
  margin-bottom: 0;
}

}
.el-col {
  border-radius: 4px;
}

.bg-purple-dark {
  background: #99a9bf;

}

.bg-purple {
  background: #f5f5f5;
  padding: 40px 0;
  text-align: center;
}

.bg-purple img {
  height: 120px;

}

.bg-purple-light {
  background: #e5e9f2;
}

.grid-content {
  border-radius: 4px;
  min-height: 36px;
}

.row-bg {
  padding: 10px 0;
  background-color: #f9fafc;
}

.into_title {
  font-weight: 550;
  font-size: 19px;
  margin: 10px;
}

.into {
  font-size: 13px;
  margin: 5px 10px;
  color: #909090;
}

.into_more {
  border: 2px solid #000000;
  width: 100px;
  text-align: center;
  margin: 10px;
  border-radius: 20px;
  padding: 8px;
  font-weight: 550;
  cursor: pointer;
  font-size: 13px;
}


/*修改滚动条样式*/
div::-webkit-scrollbar {
  width: 10px;
  height: 0px;
  /**/
}

div::-webkit-scrollbar-track {
  background: rgb(239, 239, 239);
  border-radius: 2px;
}

div::-webkit-scrollbar-thumb {
  background: #bfbfbf;
  border-radius: 10px;
}

div::-webkit-scrollbar-thumb:hover {
  background: #333;
}

div::-webkit-scrollbar-corner {
  background: #179a16;
}

/* 移动端css */
@media (max-width:767px) {
	.el-row{
		margin-top: 0;
	}
	.info_content{
		width: 100% ;
		margin: 0 ;
		left: 0;
		box-sizing: border-box;
	}
	.btn_content{
		position: absolute;
		bottom: 15% ;
		left: 2%;
	}
	.btn_content .btn{
		color: #fff;
		margin-right: 5px;
		background-color: #303030;
		padding: 10px 20px;
		font-size: 12px;
		border-radius: 25px;
		
	}
	.bg-purple{
		/* background-color: #393945; */
		/* border-radius:100px ; */
		border:1px solid #efefef;
		box-sizing: border-box !important;
	}
	.bg-purple img{
		height: 100px;
		
	}
	.moblie_text{
		position: absolute;
		text-align: center;
		width: 100%;
		top: 20%;
		color: #FFFFFF;
		font-size: 30px;
	}
	.into_more{
		margin-bottom: 30px;
	}
}

</style>
