<template>
	<div >
		<div class="banner" v-if="this.isPc">
			<img src="static/server_banner.png"  width="100%"/>
		</div>
		<div class="banner" v-if="!this.isPc">
			<img src="static/ask_banner.png"  width="100%" />
			<p class="sever_text">服务咨询</p>
		</div>
		<el-form class="server_answer" :label-position="labelPosition" label-width="100px" :model="ruleForm" :rules="rules" ref="ruleForm">
		  <el-form-item label="联系人:"  style="width: 100%;" prop="name">
		    <el-input v-model="ruleForm.name" ></el-input>
		  </el-form-item>
		  <el-form-item label="手机号:" style="width: 100%; " prop="phone_number">
		    <el-input v-model="ruleForm.phone_number" ></el-input>
		  </el-form-item>
		  <el-form-item label="工作邮箱:" prop="email">
		    <el-input v-model="ruleForm.email"></el-input>
		  </el-form-item>
		  <el-form-item label="公司名称:" prop="company_name">
		    <el-input v-model="ruleForm.company_name" prop='company_name'></el-input>
		  </el-form-item>
		   <el-form-item label="咨询问题:" prop="intention">
		      <el-input type="textarea" v-model="ruleForm.intention"></el-input>
		    </el-form-item>
			<p class="form_into">
				温馨提示：
			</p>
			<p class="form_into">
				1:反馈会在一周内与您联系（每周一到周五的9:00至18：00）。
			</p>
			<p class="form_into">
				2:紧急反馈电话请拨打400-019-2795进行快速答疑。
			</p>
			  <el-button style="margin-top: 50px;width: 50%;" type="danger" @click="submitForm('ruleForm')" round>立即提交</el-button>
		</el-form>
	</div>
</template>
<script>
	export default {
	    data() {
	      return {
	        labelPosition: 'right',
	        ruleForm: {
	          name: '',
			  phone_number:'',
	          email: '',
	          company_name: '',
			  intention:''
	        },
			rules: {
			          name: [
			            { required: true, message: '请输入联系人姓名', trigger: 'blur' },
			            { min: 2, max: 5, message: '长度在 3 到 5 个字符', trigger: 'blur' }
			          ],
			          email: [
			            { required: false, message: '请填写工作邮箱', trigger: 'change' }
			          ],
			          company_name: [
			            { required: true, message: '请填写公司名称', trigger: 'change' }
			          ],
			          phone_number: [
			            { required: true, message: '请输入联系人手机号', trigger: 'change' },
						{ min: 11, max: 11, message: '手机号有误', trigger: 'blur' }
			          ],
			          intention: [
			            { required: false, message: '请填写咨询问题', trigger: 'blur' }
			          ]
			        }
	      };
	    },
		 methods: {
		      submitForm(formName) {

		        this.$refs[formName].validate((valid) => {
		          if (valid) {
						console.log(this.$refs[formName])
						var that = this;
						const url =  this.httpsUrl+'/api/index.php?act=corporation';
						var arr={
							name:""
						}
						 this.$http.post(url,this.ruleForm,{emulateJSON: true}).then(
						     response => {

						         this.$message({
						         showClose: true,
                                  message: '提交成功，稍后我们客服人员就会联系你，请你保持手机畅通谢谢。',
                                  type: 'success'
                                });
                                this.resetForm('ruleForm');
						     },
						     response => {
						       
						         this.$message({
						         showClose: true,
                                  message: '提交失败，请检查确认后再进行提交。',
                                  type: 'error'
                                });
                                 this.resetForm('ruleForm');
						     },
						 );







		          } else {
		            console.log('error submit!!');
		            return false;
		          }
		        });
		      },
		      resetForm(formName) {
		        this.$refs[formName].resetFields();
		      }
		    }
	  }
</script>

<style>
	.server_answer{
		width: 1000px;
		text-align: center;
		left: 50%;
		margin-left: -500px;
		position: relative;
		overflow: hidden;
		box-sizing: border-box;
		padding: 50px;
		border: 1px solid #efefef;
		border-radius: 10px;
		background-color: #FFFFFF;
		position: relative;
		top: -100px;
	}
	.form_into{
		font-size: 12px;
		color: #909090;
		text-align: left;
	}
	@media (max-width:767px) {
		.sever_text{
			text-align: center;
			color: #fff;
			width: 100%;
			font-weight: 600;
			font-size: 30px;
			margin-top:-35%;
			position: absolute;
		}
		.line{
			width: 34%;
			background-color: #000000;
		}
		.name{
			width: 30%;
			text-align: center;
		}
		.server_answer{
			width: 90% ;
			margin: 0 ;
			left: 0;
			left: 5%;
			box-sizing: border-box;
			padding: 10px;
			top: 20px;
			margin-bottom: 30px;
		}
		.video_contaniner{
			width: 100%;
		}
	}
</style>
