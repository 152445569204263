<template>
  <div>
    <div class="block">
      <el-carousel indicator-position="outside" :height="bannerHeight + 'px'">
        <el-carousel-item v-for="(item,index) in BannerImg" :key="index">
          <img class="bannerImg" :src="item" @click="toDetail(index)"/>
        </el-carousel-item>
      </el-carousel>
    </div>
    <!-- 推荐信息部分 -->
    <div class="info">
      <p class="info_title">推荐信息</p>
      <p class="info_into">同蓝天，九五云，人人得到名师教学</p>
      <p class="info_line"></p>
    </div>
    <!-- 信息部分1 -->
    <div class="info_content" v-if="this.isPc">
      <el-row :gutter="20">
        <el-col :span="16">
          <div class="grid-content bg-purple" @click="toDetail(0)">
            <img src="static/recom_one.png"/>
            <div class="info_container" >
              <p class="title_content">
                九五尊易纳米智慧黑板
              </p>
              <p class="title_detail">
                机制融合 互动体验升级
              </p>
              <p class="more_info">了解更多</p>
            </div>

          </div>
        </el-col>
        <el-col :span="8">
          <div class="grid-content bg-purple" @click="toDetail(5)">
            <img src="static/recom_four.png"/>
            <div class="info_container">
              <p class="title_content">
                九五尊易链阵笔
              </p>
              <p class="title_detail">
                全新配色 操作颜值NO.1
              </p>
              <p class="more_info">了解更多</p>
            </div>
          </div>
        </el-col>
      </el-row>
      <el-row :gutter="20">
        <el-col :span="8">
          <div class="grid-content bg-purple" @click="toDetail(7)">
            <img src="static/recom_three.jpg"/>
            <div class="info_container" >
              <p class="title_content">
                九五尊易智慧云班牌
              </p>
              <p class="title_detail">
                班级信息 学校共同融合
              </p>
              <p class="more_info">了解更多</p>
            </div>

          </div>
        </el-col>
        <el-col :span="16" >
          <div class="grid-content bg-purple" @click="toDetail(6)">
            <img src="static/recom_two.png"/>
            <div class="info_container">
              <p class="title_content">
                九五尊易AI千里眼
              </p>
              <p class="title_detail">
                创新性能满足更多需求
              </p>
              <p class="more_info">了解更多</p>
            </div>
          </div>
        </el-col>
      </el-row>
      <el-row :gutter="20">
        <el-col :span="8">
          <div class="grid-content bg-purple">
            <img src="static/pr_01.png"/>
            <div class="info_container">
              <p class="title_content">
                链产品
              </p>
              <p class="more_info">了解更多</p>
            </div>
          </div>
        </el-col>
        <el-col :span="8">
          <div class="grid-content bg-purple">
            <img src="static/pr_02.png"/>
            <div class="info_container">
              <p class="title_content">
                链服务
              </p>
              <p class="more_info">了解更多</p>
            </div>
          </div>
        </el-col>
        <el-col :span="8">
          <div class="grid-content bg-purple">
            <img src="static/pr_03.png"/>
            <div class="info_container">
              <p class="title_content">
                九五云
              </p>
              <p class="more_info">了解更多</p>
            </div>
          </div>
        </el-col>
      </el-row>
    </div>
	<!-- 移动端信息部分1 -->
	<div class="info_content" v-if="!this.isPc">
	  <el-row :gutter="20">
	    <el-col :span="24">
	      <div class="grid-content bg-purple" @click="toDetail(0)">
	        <img src="static/pro_1/img1.png"/>
	        <div class="info_container" >
	          <p class="title_content">
	            九五尊易纳米智慧黑板
	          </p>
	          <p class="title_detail">
	            机制融合 互动体验升级
	          </p>
	          <p class="more_info">了解更多</p>
	        </div>

	      </div>
	    </el-col>
		<el-col :span="24">
		  <div class="grid-content bg-purple" @click="toDetail(8)">
		    <img src="static/pro_9/img3.png"/>
		    <div class="info_container" >
		      <p class="title_content">
		        九五尊易智慧纳米一体机
		      </p>
		      <p class="title_detail">
		        班级信息 学校共同融合
		      </p>
		      <p class="more_info">了解更多</p>
		    </div>

		  </div>
		</el-col>

	  </el-row>
	  <el-row :gutter="20">
		  <el-col :span="24">
		    <div class="grid-content bg-purple" @click="toDetail(5)">
		      <img src="static/pro_6/img2.png"/>
		      <div class="info_container">
		        <p class="title_content" style="color: #606060;">
		          九五尊易链阵笔
		        </p>
		        <p class="title_detail" style="color: #606060;">
		          全新配色 操作颜值NO.1
		        </p>
		        <p class="more_info">了解更多</p>
		      </div>
		    </div>
		  </el-col>
	    <el-col :span="24" >
	      <div class="grid-content bg-purple" @click="toDetail(6)">
	        <img src="static/pro_7/img2.png"/>
	        <div class="info_container">
	          <p class="title_content" style="color: #606060;">
	            九五尊易AI千里眼
	          </p>
	          <p class="title_detail" style="color: #606060;">
	            创新性能满足更多需求
	          </p>
	          <p class="more_info">了解更多</p>
	        </div>
	      </div>
	    </el-col>
	  </el-row>
	  <el-row :gutter="0">
		  <el-col :span="12" >
		    <div class="grid-content bg-purple"  @click="toLxt()">
		      <img src="static/pr_03.png"/>
		      <div class="info_container">
		        <p class="title_content" style="color: #fff;">
                九五云
		        </p>
		        <p class="more_info">了解更多</p>
		      </div>
		    </div>
		  </el-col>
		  <el-col :span="12"  >
		    <div class="grid-content bg-purple" @click="toPro()">
		      <img src="static/pr_01.png"/>
		      <div class="info_container">
		        <p class="title_content" style="color: #fff;">
		        链产品
		        </p>
		        <p class="more_info">了解更多</p>
		      </div>
		    </div>
		  </el-col>
	  </el-row>
	</div>

    <!-- 视频部分 -->
    <div class="video">

    </div>
    <!-- 九五动态 -->
    <div class="trends" >
      <p class="info_title">九五动态</p>
      <p class="info_line"></p>
      <el-row :gutter="20" class="moblie_video">
        <el-col :span="home_span">
          <div class="grid-content bg-purple">
            <video-player class="video-player vjs-custom-skin"
                          ref="videoPlayer"
                          :playsinline="true"
                          :options="playerOptions"
            ></video-player>
          </div>
        </el-col>
        <el-col :span="11" :offset="2" v-if="this.isPc">
          <div class="grid-content bg-purple trends_right"
               style="height: 251px;overflow: hidden; box-sizing: border-box;">
            <div class="news_title">新闻资讯</div>
            <div v-for="(item,index) in exampleList" :key=index>
              <div class="news_content" @click="toExample(item.article_id)">
                <div class="news_title_tips">{{ item.title }}</div>
                <div class="news_time">{{ item.time }}</div>
              </div>
            </div>
          </div>
        </el-col>
      </el-row>
    </div>
  </div>
</template>

<script>
export default {
  data () {
    return {
      bannerHeight: '',
      screenWidth: '',
	  moblie_left: 16,
	  moblie_right: 8,
	  home_span: 11,
      exampleList: [],
      BannerImg: [
        'static/banner_two.png',
        'static/banner_three.jpg',
        'static/banner_one.png'
      ],
      playerOptions: {
        // playbackRates: [0.7, 1.0, 1.5, 2.0], //播放速度
        autoplay: false, // 如果true,浏览器准备好时开始回放。
        muted: false, // 默认情况下将会消除任何音频。
        loop: false, // 导致视频一结束就重新开始。
        // pause:true,
        preload: 'auto', // 建议浏览器在<video>加载元素后是否应该开始下载视频数据。auto浏览器选择最佳行为,立即开始加载视频（如果浏览器支持）
        language: 'zh-CN',
        aspectRatio: '16:9', // 将播放器置于流畅模式，并在计算播放器的动态大小时使用该值。值应该代表一个比例 - 用冒号分隔的两个数字（例如"16:9"或"4:3"）
        fluid: true, // 当true时，Video.js player将拥有流体大小。换句话说，它将按比例缩放以适应其容器。
        sources: [{
          type: '', // 这里的种类支持很多种：基本视频格式、直播、流媒体等，具体可以参看git网址项目
          src: 'https://oss-prd.oss-cn-hangzhou.aliyuncs.com/95isee/1/4829/5b06c2b4-56d9-41af-a56e-3f9b67524ae6.mp4' // url地址
        }],
        poster: 'static/videoPost.jpg', // 你的封面地址
        // width: document.documentElement.clientWidth, //播放器宽度
        notSupportedMessage: '此视频暂无法播放，请稍后再试', // 允许覆盖Video.js无法播放媒体源时显示的默认信息。
        controlBar: {
          timeDivider: true,
          durationDisplay: true,
          remainingTimeDisplay: false,
          fullscreenToggle: true // 全屏按钮
        }
      }
    }
  },
  mounted () {
    this.screenWidth = window.innerWidth
    this.setSize()
    var that = this
    window.addEventListener('resize', function () {
      that.screenWidth = window.innerWidth
      that.setSize()
    }, false)

    if (!this.isPc) {
      this.moblie_right = 24
      this.moblie_left = 24
      this.home_span = 24
      this.BannerImg = [
		  'static/moblie_one.jpg',
		  'static/moblie_two.jpg',
		  'static/moblie_three.jpg'
      ]
    }

    // 获取案例中心
    var that = this
    const url = this.httpsUrl + '/api/index.php?act=caseList'
    // this.$http.get(url).then(
    //   response => {
    //     console.log(response.body)
    //     this.exampleList = response.body.list
    //     // this.repositoryUrl = result.html_url;
    //     // this.repositoryName = result.name;
    //   },
    //   response => {
    //     // alert('请求失败');
    //   }
    // )
  },
  methods: {
    setSize: function () {
      this.bannerHeight = 700 / 1920 * this.screenWidth
	  if (!this.isPc) {
        this.bannerHeight = 1000 / 1920 * this.screenWidth
	  }
      console.log(this.screenWidth)
    },
    toDetail: function (index) {
      if (index == 1) {
        index = 5
      }
      if (index == 2) {
        index = 6
      }
      this.$router.push({ path: '/template', query: { num: index } })
    },
    // 去案例详情
    toExample: function (id) {
      this.$router.push({ path: '/templateDetail', query: { num: id } })
    },
    // 去九五云
    toLxt: function () {
      window.location.href = 'https://www.95isee.cn/cloud/#/Login/1'
    },
    // 去产品页
    toPro: function () {
      this.$router.push('/product')
    },
    toASK: function () {
      this.$router.push('/server_2')
    }
  }
}
</script>
<style scoped="scoped">
.el-carousel__item h3 {
  color: #475669;
  font-size: 14px;
  opacity: 0.75;
  line-height: 150px;
  margin: 0;
}

.el-carousel__item:nth-child(2n) {
  background-color: #99a9bf;
}

.el-carousel__item:nth-child(2n+1) {
  background-color: #d3dce6;
}

.bannerImg {
  width: 100%;
  height: inherit;
  /* margin-left: -10%; */
}

.block {
  cursor: pointer;
}

.info {
  text-align: center;
}

.info_title {
  font-size: 16px;
  color: #606060;
  padding: 5px;
}

.info_into {
  font-size: 14px;
  color: #606060;

}

.info_content {
  width: 1000px;
  margin: 20px 0;
  left: 50%;
  margin-left: -500px;
  position: relative;
}

.info_content img {
  width: 100%;
  height: 315px;
  box-shadow: 5px 5px 5px #F2F6FC;
}

.info_line {
  width: 30px;
  border: 2px solid #df2228;
  border-radius: 5px;
  position: relative;
  left: 50%;
  margin-left: -15px;
}

/* 信息布局 */
.el-row {
  margin-top: 20px;
  max-width: 1200px;

&
:last-child {
  margin-bottom: 0;
}

}
.el-col {
  border-radius: 4px;
}

.bg-purple-dark {
  background: #99a9bf;

}

.bg-purple {
  position: relative;
  cursor: pointer;
}

.info_container {
  position: absolute;
  bottom: 20px;
  left: 20px;
}

.title_content {
  color: #FFFFFF;
  font-weight: 600;
  font-size: 23px;
}

.title_detail {
  padding: 2px 0;
  color: #FFFFFF;
  font-size: 14px;
}

.more_info {
  padding: 2px 0;
  color: #000;
  font-size: 14px;
  font-weight: 550;
  height: 0px;
  overflow: hidden;
  transition: height 1s;
  margin-left: 5px;
  text-decoration: underline;
}

.bg-purple:hover .more_info {
  height: 25px;
}

.grid-content {
  border-radius: 4px;
  min-height: 36px;
}

.row-bg {
  padding: 10px 0;
  background-color: #f9fafc;
}

/* 九五动态 */
.trends {
  width: 1000px;
  margin: 20px 0;
  text-align: center;
  left: 50%;
  margin-left: -500px;
  position: relative;
}

.trends_right {
  padding: 15px 20px;
}

.news_title {
  text-align: left;
  color: #c1c1c1;
  font-size: 12px;

}

.news_content {
  text-align: left;
  margin-top: 15px;
}

.news_time {
  color: #c1c1c1;
  font-size: 13px;
}

/* 移动端设置 */
@media (max-width:767px) {
	.info_content{
		width: 100% ;
		margin: 0 ;
		padding: 30px 0 0 0;
		left: 0;
		box-sizing: border-box;

	}
	.info_content img{
		height: auto;display: inherit;
	}
	.title_content{
		/* color: #000; */
		text-align: center;
		font-size: 20px;
	}
	.title_detail{
		/* color: #606060; */
		font-size: 12px;
	}
	.el-row{
		margin: 0;
	}
	.info_line{
		margin-bottom: 20px;
	}
	.trends{
		width: 100% ;
		margin: 0 ;
		padding:30px 0 0 0;
		left: 0;
		box-sizing: border-box;
	}
}
</style>
