import 'babel-polyfill'
import Vue from "vue";
import ElementUI from "element-ui";
import promise from 'es6-promise'
import Navi from "./components/Navi/Navi.vue";
import router from "./router";

import BaiduMap from "vue-baidu-map";

import VideoPlayer from "vue-video-player";

import "element-ui/lib/theme-chalk/index.css";
import "video.js/dist/video-js.css";
import "vue-video-player/src/custom-theme.css";

promise.polyfill()

Vue.use(VideoPlayer);

Vue.prototype.httpsUrl = "";

if (
  navigator.userAgent.match(
    /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
  )
) {
  Vue.prototype.isPc = false;
} else {
  Vue.prototype.isPc = true; // alert('PC端')
}

Vue.use(ElementUI);
Vue.use(BaiduMap, {
  // ak 是在百度地图开发者平台申请的密钥 详见 http://lbsyun.baidu.com/apiconsole/key */
  ak: "ZbUKUzbSgkM6RbaLMY2AFmX2kf3uMplb"
});
new Vue({
  el: "#app",
  router,
  render: h => h(Navi)
}).$mount('#app');
