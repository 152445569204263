import Vue from "vue";
import VueRouter from "vue-router";
import home from "../components/home.vue";
import product from "../components/product.vue";
import server from "../components/server.vue";
import server_1 from "../components/server_1.vue";
import server_2 from "../components/server_2.vue";
import server_3 from "../components/server_3.vue";
import example_page from "../components/example.vue";
import about_page from "../components/about.vue";

import ask from "../components/ask.vue";
import video from "../components/video.vue";

import templateDetail from "../components/example_detail.vue";

import template from "../components/template.vue";
Vue.use(VueRouter);

const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err);
};

const router = new VueRouter({
  routes: [
    { path: "/", redirect: "/home" },

    {
      path: "/home",
      component: home
    },
    {
      path: "/product",
      component: product
    },
    {
      path: "/server",
      component: server
    },
    {
      path: "/server_1",
      component: server_1
    },
    {
      path: "/server_2",
      component: server_2
    },
    {
      path: "/server_3",
      component: server_3
    },
    {
      path: "/example",
      component: example_page
    },
    {
      path: "/about",
      component: about_page
    },
    {
      path: "/template",
      component: template
    },
    {
      path: "/templateDetail",
      component: templateDetail
    },
    {
      path: "/ask",
      component: ask
    },
    {
      path: "/video/:id",
      component: video
    }
  ]
});

export default router;
