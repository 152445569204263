<template>
  <div>

    <div class="header" v-if="this.isPc">
      <div class="header_left">
        <img class="header_logo" src="static/logo.png"/>
      </div>
      <div class="header_menu">
        <el-menu :default-active="activeIndex" type="flex" justify='center' class="el-menu-demo" mode="horizontal"
                 @select="handleSelect">
          <el-menu-item class="item" index="1">首页</el-menu-item>
          <el-submenu index="2">
            <template slot="title">产品</template>
            <div class="menu_div">
              <div class="menu_left">
                <el-menu-item index="2" v-for="(item,index) in arr" :key="index" @mouseover.native="mouse(index)"
                              @click="toDetail(item.item)" class="menu_item_pro">
                  <img class="item_img" :src="item.img"/>
                  <p class="item_name">{{item.name}}</p>
                </el-menu-item>
              </div>
              <div class="menu_right">
                <img :src="mianSrc"/>
              </div>
            </div>
            <div class="menu_bottom">
              <p class="go_home" @click="toHome()">
                <i class="el-icon-s-home" style="color:#e02b30;font-size: 22px; margin-right: 20px;"></i>
                九五尊易
              </p>
            </div>
          </el-submenu>
          <el-submenu index="3">

            <template slot="title">服务</template>
            <div class="go_home">
              <el-menu-item index="3-1" class="menu_item">下载中心</el-menu-item>
              <el-menu-item index="3-2" class="menu_item">视频中心</el-menu-item>
              <el-menu-item index="3-3" class="menu_item">服务咨询</el-menu-item>
              <el-menu-item index="3-4" class="menu_item">常见问题</el-menu-item>
            </div>
            <div class="menu_bottom">
              <p class="go_home" @click="toHome()">
                <i class="el-icon-s-home" style="color:#e02b30;font-size: 22px; margin-right: 20px;"></i>
                九五尊易
              </p>
            </div>
          </el-submenu>
          <el-menu-item class="item" index="4">案例</el-menu-item>
          <el-menu-item class="item" index="5">关于九五</el-menu-item>
          <el-menu-item class="item" index="6">
            <el-link href="https://www.95isee.cn/cloud/#/Login/1" target="_self">九五云</el-link>
          </el-menu-item>
        </el-menu>
      </div>
    </div>

	<div class="moblie_menu" v-if="!this.isPc" >
		<el-menu default-active="activeIndex"  background-color="#000"
      text-color="#fff" class="el-menu-vertical-demo moblie_content" :collapse="isCollapse"  @select="handleSelect">
		  <el-submenu index="88"  ref="elMenu" >
		    <template slot="title">
		      <img class="moblie_logo" src="static/logo.png"/>
		      <span slot="title" style="color: #ca2b25;">九五尊易</span>
			  <i class="right_menu el-submenu__icon-arrow el-icon-s-fold"></i>
		    </template>
			<el-menu-item-group>
			  <el-menu-item index="1">首页</el-menu-item>
			</el-menu-item-group>
		    <el-menu-item-group>
		      <el-menu-item index="2">产品</el-menu-item>
		    </el-menu-item-group>
		    <el-submenu index="3">
		      <span slot="title">服务</span>
			  <el-menu-item index="3-1">下载中心</el-menu-item>
			  <el-menu-item index="3-2">视频中心</el-menu-item>
			  <el-menu-item index="3-3">服务咨询</el-menu-item>
			  <el-menu-item index="3-4">常见问题</el-menu-item>
		    </el-submenu>
			 <el-menu-item-group>
				<el-menu-item index="4">案例</el-menu-item>
			 </el-menu-item-group>
			 <el-menu-item-group>
			 	<el-menu-item index="5">关于九五</el-menu-item>
			 </el-menu-item-group>
        <el-menu-item-group>
          <el-menu-item class="item" index="6">
            <el-link href="https://www.95isee.cn" target="_self">九五云</el-link>
          </el-menu-item>
        </el-menu-item-group>
		  </el-submenu>
		</el-menu>
	</div>
    <div>
      <router-view></router-view>
    </div>

    <!-- 侧边栏 -->
    <!--  -->
    <div class="left_scoll" v-if="isPc">
      <div class="scoll_up" @click="toTop()"><i class="el-icon-arrow-up"></i></div>
      <div class="scoll_ask_content" v-show="showAdr">
        <p>上海九五尊易信息科技有限公司</p>
        <p>地址:上海市闵行区新建东路58弄2号2208室</p>
        <p>邮箱:95@95isee.com</p>
        <p>电话:400-019-2795</p>
      </div>
      <div class="scoll_phone" @mouseenter="showAdress()" @mouseleave="leave()"><i class="el-icon-phone"></i></div>

      <div class="scoll_ask" @click="handleSelect('3-3')">获取报价</div>
    </div>
    <div class="footer">
      <div class="footer_content">
        <el-row :gutter="20">
          <el-col :span="footer_span">
            <el-row style="margin-top: 0;">
              <el-col :span="7">
                <div class="grid-content bg-purple footer_main">
                  <p class="footer_tittle">查看产品</p>
                  <p class="footer_link" @click="toDetail(0)">智能黑板</p>
                  <p class="footer_link" @click="toDetail(1)">一体机</p>
                  <p class="footer_link" @click="toDetail(5)">链阵笔</p>
                  <p class="footer_link" @click="toDetail(7)">智能班牌</p>
                </div>
              </el-col>
              <el-col :span="7" :offset="1">
                <div class="grid-content bg-purple footer_main">
                  <p class="footer_tittle">公司服务</p>
                  <p class="footer_link" @click="handleSelect('3-1')">下载专区</p>
                  <p class="footer_link" @click="handleSelect('3-4')">常见问题</p>
                  <p class="footer_link" @click="handleSelect('3-3')">服务咨询</p>
                  <p class="footer_link" @click="handleSelect('3-2')">视频中心</p>
                </div>
              </el-col>
              <el-col :span="7" :offset="1">
                <div class="grid-content bg-purple footer_main">
                  <p class="footer_tittle">关于九五</p>
                  <p class="footer_link" @click="handleSelect('5')">公司介绍</p>
                  <p class="footer_link" @click="handleSelect('5')">联系我们</p>
                  <p class="footer_link">加入我们</p>
                  <p class="footer_link"><a href="https://www.95isee.cn" target="_self">九五云</a></p>
                  <!-- <p class="footer_link" @click="handleSelect('3-3')">留言建议</p> -->
                </div>
              </el-col>
            </el-row>
          </el-col>
          <el-col :span="footer_span" :offset="offset_span" class="moblie_class">
            <div class="grid-content bg-purple trends_right">
              <div class="all_content">
                <p class="tel_title">官方热线电话</p>
                <p class="tel_num">021-8037-0595</p>
                <p class="tel_title">售后热线电话</p>
                <p class="tel_num">400-019-2795</p>
                <div class="ercod_content">
                  <div class="menu_ercode">
                    <p class="ercod_img"><img src="static/wechat.png"/></p>
                    <p class="ercod_title">九五微信公众号</p>
                  </div>
                  <div class="menu_ercode">
                    <p class="ercod_img"><img src="static/shangch.png"/></p>
                    <p class="ercod_title">九五微信商城</p>
                  </div>
                </div>
              </div>
            </div>
          </el-col>
        </el-row>
      </div>
    </div>
  </div>
</template>
<script type="text/ecmascript-6">
export default {
  data () {
    return {
      searchCriteria: '',
      activeIndex: '1',
	  isCollapse: false,
	  offset_span: 2,
	  footer_span: 11,
      mianSrc: 'static/tan1.png',
      showAdr: false,
      breadcrumbItems: ['导航一'],
      arr: [
        {
          img: 'static/into_1.png',
          name: '智慧黑板',
          show: 'static/tan1.png',
          item: 0
        },
        {
          img: 'static/into_2.png',
          name: '测温仪',
          item: 1,
          show: 'static/tan2.png'
        },
        {
          img: 'static/into_7.png',
          name: 'AI千里眼',
          item: 6,
          show: 'static/tan3.png'
        },
        {
          img: 'static/into_6.png',
          name: '链阵笔',
          item: 5,
          show: 'static/tan4.png'
        },
        {
          img: 'static/into_8.png',
          name: '云班牌',
          item: 7,
          show: 'static/tan5.png'
        },
        {
          img: 'static/into_9.png',
          name: '一体机',
          item: 8,
          show: 'static/tan6.png'
        },
        {
          img: 'static/into_3.png',
          name: '答题器',
          item: 2,
          show: 'static/tan7.png'
        },
        {
          img: 'static/into_all.png',
          name: '查看全部',
          show: '',
          item: 999
        }
      ]
    }
  },
  mounted () {
    if (!this.isPc) {
      this.footer_span = 24
      this.offset_span = 0
    }
  },
  methods: {
    handleIconClick (ev) {
      console.log(ev)
    },
    toDetail: function (index) {
      if (index == 999) {
        this.$router.push('/product')
      } else {
        console.log(index)
        this.$router.push({ path: '/template', query: { num: index } })
      }
    },
    toPage: function (path) {
      this.$router.push(path)
    },
    toHome: function () {
      this.$router.push('/home')
    },
    mouse: function (index) {
      if (index != 8) {
        this.mianSrc = this.arr[index].show
      }
    },
    // 显示地址
    showAdress: function () {
      this.showAdr = true
    },
    leave: function () {
      this.showAdr = false
    },
    toTop: function () {
      window.scrollTo(0, 0)
    },
    handleSelect (key) {
      if (!this.isPc) {
        console.log(this.$refs.elMenu)
        this.$refs.elMenu.handleClick()
      }
      switch (key) {
        case '1':
          this.$router.push('/home')
          this.breadcrumbItems = ['导航一']
          break
        case '2':
          this.$router.push('/product')
          this.breadcrumbItems = ['导航二']
          break
        case '3-1':
          this.$router.push('/server')
          this.breadcrumbItems = ['导航三']
          break
        case '3-2':
          this.$router.push('/server_1')
          this.breadcrumbItems = ['导航三']
          break
        case '3-3':
          this.$router.push('/server_2')
          this.breadcrumbItems = ['导航三']
          break
        case '3-4':
          this.$router.push('/server_3')
          this.breadcrumbItems = ['导航三']
          break
        case '4':
          this.$router.push('/example')
          this.breadcrumbItems = ['导航四']
          break
        case '5':
          this.$router.push('/about')
          this.breadcrumbItems = ['导航五']
          break
      }
    }

  }
}
</script>
<style>
* {
  padding: 0;
  margin: 0;
  font-family: "Helvetica Neue", Helvetica, "PingFang SC", "Hiragino Sans GB", "Microsoft YaHei", "微软雅黑", Arial, sans-serif;

}

body {
  margin: 0 !important;
  padding: 0 !important;

}

.header {
  width: 100%;
  text-align: center;
  overflow: hidden;
  display: flex;
  justify-content: center;
  border-bottom: solid 1px #a7a7a7;
  box-shadow: 0 2px 5px #E6E6E6;
}

.header_left {
  float: left;
  width: 40px;
  height: 40px;
  position: absolute;
  left: 0;
}

.header_logo {
  height: 40px;
  padding: 10px;
}

.header_menu {

}

.item {
  margin: 0 20px;
}

.el-menu-demo {
  margin-right: 150px;
  border: none;
}

.el-menu--horizontal > .el-menu-item.is-active {
  border-bottom: 2px solid #df2228 !important;
}

.el-menu--horizontal > .el-submenu.is-active .el-submenu__title {
  border-bottom: 2px solid #df2228 !important;
}

.el-row {
  margin-top: 20px;
  max-width: 1200px;

&:last-child {
  margin-bottom: 0;
}

}
.el-col {
  border-radius: 4px;
}

.bg-purple-dark {
  background: #99a9bf;

}

.bg-purple {
  /* background: #f5f5f5; */
}

.bg-purple-light {
  background: #e5e9f2;
}

.grid-content {
  border-radius: 4px;
  min-height: 36px;
}

.row-bg {
  padding: 10px 0;
  background-color: #f9fafc;
}

.footer {
  width: 100%;
  position: relative;
  /* margin-top: 10px; */
  padding-top: 30px;
  border-top: 1px solid #e3e3e3;
}

.footer_content {
  width: 1000px;
  margin: 20px 0;
  text-align: center;
  left: 50%;
  margin-left: -500px;
  position: relative;
}

/*  */
.footer_main {
  text-align: left;
}

.footer_tittle {
  margin-bottom: 10px;
  font-size: 15px;
  color: #606060;
}

.footer_link {
  font-size: 12px;
  margin: 8px 0;
  color: #909090;
  cursor: pointer;
}
.footer_link a{
  text-decoration: none;
  color: #909090;
}

.tel_title {
  font-size: 12px;
  margin-bottom: 10px;
  color: #909090;
}

.tel_num {
  font-size: 15px;
  font-weight: 550;
  color: #606060;
  margin: 10px 0;
}

.menu_ercode {
  float: left;
  text-align: center;
  margin: 10px 20px 10px 0;
  box-sizing: border-box;
}

.ercod_img {
  width: 80px;
  height: 80px;
  background-color: #000000;
}

.ercod_img img {
  width: 100%;
  height: 100%;
}

.ercod_title {
  font-size: 12px;
  color: #909090;
  margin: 10px 0;
}

.all_content {
  text-align: left;
  float: right;
}

.ercod_content {
	overflow: hidden;
}

.footer_link:hover {
  text-decoration: underline;
}

/* menu 修改 */
.el-menu--horizontal {
  width: 100%;
  left: 0 !important;
}

.menu_item {
  float: left !important;
  width: 25%;
  text-align: center;
  /* padding: 50px 0!important;
   */
  height: 100px !important;
  line-height: 100px !important;
  box-sizing: border-box;

}

.menu_bottom {
  height: 50px;
  background-color: #f0f0f0;
  width: 100%;
  position: absolute;
  bottom: -50px;
  color: #000;
  line-height: 50px;
}

.menu_div {
  width: 1000px;
  margin-bottom: 40px;
  text-align: center;
  left: 50%;
  margin-left: -500px;
  position: relative;
  overflow: hidden;
}

.item_name {
  font-size: 12px;
  color: #909090;
  margin-top: 10px;
}

.item_img {
  margin-top: 15px;
  width: auto;
  height: 50px;
  max-width: 70px;
}

.menu_item_pro {
  width: 100px;
  height: 100px !important;
  box-sizing: border-box;
  cursor: pointer;
  float: left !important;
  margin: 10px;
}

.menu_left {
  width: 650px;
  box-sizing: border-box;
  padding: 20px;
  overflow: hidden;
  float: left;
  box-sizing: border-box;
}

.menu_right {
  width: 350px;
  float: left;
  background-color: #EFEFEF;
  box-sizing: border-box;
  text-align: center;
  margin-top: 30px;
  height: 300px;
}

.menu_right img {
  height: 100%;
}

.go_home {
  text-align: left;
  width: 900px;
  left: 50%;
  margin-left: -450px;
  position: relative;
  overflow: hidden;
  cursor: pointer;
}

.left_scoll {
  position: fixed;
  right: 0;
  top: 45%;
  width: 40px;
  height: 100px;
  z-index: 999;
  cursor: pointer;
}

.scoll_phone {
  height: 40px;
  width: 100%;
  background-color: #b0b0b0;
  margin-top: 3px;
  font-size: 30px;
  color: #fff;
  line-height: 40px;
  text-align: center;

}

.scoll_ask {
  height: 40px;
  width: 100%;
  margin-top: 3px;
  background-color: #5c7ef9;
  font-size: 12px;
  box-sizing: border-box;
  padding: 4px;
  color: #fff;
  text-align: center;
}

.scoll_up {
  background-color: #b0b0b0;
  height: 40px;
  width: 100%;
  color: #fff;
  font-size: 30px;
  text-align: center;
}

.scoll_ask_content {
  background-color: #b0b0b0;
  position: absolute;
  right: 42px;
  width: 200px;
  margin-top: 3px;
  padding: 10px;
  font-size: 13px;
  color: #FFFFFF;

}

/* 移动端页面 */

@media (max-width:767px) {
	body{
		width: 100%;
		height: auto;
		overflow-x: hidden;
	}
	.right_menu{
		font-size: 36px !important;
		    position: absolute;
		    right: 8px !important;
		    color: #fff !important;
		    top: 16px !important;
		    z-index: 5;
	}
	.el-submenu__title i{
		color: #fff !important;
		/* font-size: 10px; */
	}
	.el-submenu__title .el-submenu__icon-arrow{
		/* display: none !important; */
	}
	.moblie_menu{
	position: relative;
	height: 56px;
	width: 100%;
	}
	.moblie_content{
		position: absolute;
		z-index: 10;
		width: 100%;
		background-color: #000 !important;
		margin-bottom: 50px;
	}
	.moblie_logo{
		width: 40px;
	}
	.el-submenu__title:hover{
		background-color: #000 !important;
	}
	.footer_content{
		width: 100% ;
		margin: 0 ;
		padding: 30px 0;
		left: 0;
		box-sizing: border-box;
		background-color: #393945;

	}
	.footer_tittle{
		color: #F0F0F0;
	}
	.footer_main {
	  text-align: center ;
	}
	.tel_title{
		text-align: center;
	}
	.all_content{
		text-align: center;
		position: relative;
		left: 0;
		margin-top: 20px;
		width: 100%;
		position: relative;
		left: 0;

	}

	.moblie_class{
		margin: 0;
		padding: 0;
	}
	.menu_ercode{
		display: inline-block;
		float: none;
		margin-left: 5%;
	}
}
</style>
