<template>
	<div class="body">
		<div class="banner" v-if="this.isPc">
			<img src="static/example_banner.png"  width="100%"/>
		</div>
		<div class="banner" v-if="!this.isPc">
			<img src="static/example_banner.png"  width="160%" style="margin-left: -30%;"/>
		</div>
		<div class="detail_content" >
			<p class="example_name">{{article.title}}</p>
			<div class="example_from">
				<div class="from_left">
					<img src="static/user.png" />
					<span>{{article.author}}</span>
				</div>
				<div class="from_right">
					{{article.time}}
				</div>
			</div>
			<!-- content -->
			<div class="example_content" v-html="article.content">
				<!-- {{article.content}} -->
			</div>
			<div class="return" @click="$router.back(-1)">返回上一页</div>
		</div>

	</div>
</template>

<script>
	export default {
	   data(){
		   return{
				"on":true,
				"article":{
					title:"121323",
					"author":12313,
					"time":12313,
					"content":'1132132123111111111'
				}
			}
	   },
	   mounted(){
		// 获取案例中心

		const id = this.$route.query.num;
		var that = this;
		window.scrollTo(0,0);
		const url =  this.httpsUrl+'/api/index.php?act=caseInfo&id='+id;
		            this.$http.get(url).then(
		                response => {

		                    console.log(response.body);
							this.article = response.body.info;
		                    // this.repositoryUrl = result.html_url;
		                    // this.repositoryName = result.name;
		                },
		                response => {
		                    // alert('请求失败');
		                },
		            );
	  },
	   methods : {
			// 返回上一页

		},
	}
</script>

<style scoped="scoped">
	.body{
		/* background-color: #f5f5f5; */
	}
	.banner{
		position: relative;
		width: 100%;
		overflow-x: hidden;
	}
	.detail_content{
		width: 1000px;
		/* margin: 20px 0; */
		text-align: center;
		left: 50%;
		margin-left: -500px;
		position: relative;
		background-color: #fff;
		overflow: hidden;
		top: 50%;
		z-index: 10;
		min-height: 500px;
		cursor: pointer;
		margin-top: -10%;
		margin-bottom: 30px;
		border: 1px solid #E0E0E0;
		border-radius: 10px;
		box-shadow: 0px 0px 50px #FFFFFF;
	}
	.example_left{
		width: 50%;
		float: left;
		box-sizing: border-box;
		text-align: left;
		padding: 10px 20px;
	}
	.example_right{
		width: 50%;
		float: left;
	}
	.example_right img{
		width: 100%;
		height: 245px;
	}
	.example_name{
		color: #606060;
		text-align: left;
		line-height: 30px;
		margin: 10px;
		font-weight: 600;
		font-size: 20px;
		text-align: center;
		/* height: 45px; */
	}
	.example_into{
		margin: 10px 0;
		font-size: 13px;
		width: 100%;
		min-height: 130px;
		color: #909090;
	}
	.example_from{
		width: 100%;
		height: 30px;
		padding-bottom:20px;
		border-bottom: 1px solid #E0E0E0;
	}
	.from_left{
		width: 50%;
		float: left;
	}
	.from_left img{
		width: 30px;
		height: 30px;
		border-radius: 50px;
		vertical-align: middle;

	}
	.from_left span{
		margin-left: 10px;
		font-size: 13px;
	}
	.from_right{
		width: 50%;
		float: right;
		line-height: 30px;
		color: #000000;
		font-size: 13px;

	}
	.example_content{
		    font-size: 14px!important;
		    min-height: 250px;
		    overflow: hidden;
		    width: 900px;
		    margin: 50px;
	}
	.example_content span{
		font-size: 14px !important;
	}
	.example_content img{
		width: 100% !important;
	}
	.example_content >>> img {
	    width: 100% !important;
	    height: auto;
	  }
	.return{
		padding: 20px 50px;
		text-align: right;
		font-size: 14px;
		color: #606060;
		text-decoration: underline;
	}
	@media (max-width:767px) {
		.detail_content{
			width: 95% ;
			margin: 2.5%;
			left: 0;
			position: static;
			padding: 10px;
			box-sizing: border-box;
		}
		.example_left{
			width: 100%;
		}
		.example_content{
				padding: 20px 0;
			    width: 100%;
			    margin: 0;
		}
		.example_content >>> img{
		    width: 100%!important;
		    height: auto;
		    position: relative;
		    left: 0;
		    margin-left: -28px;
		}
	}
</style>
