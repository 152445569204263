<template>
	<div>
		<div class="banner" v-if="this.isPc">
			<img src="static/example_banner.png"  width="100%"/>
		</div>
		<div class="banner" v-if="!this.isPc">
			<img src="static/example_banner.png"  width="160%" style="margin-left: -30%;"/>
		</div>
		<div class="example" >
			<div class="example_content" v-for="(item,index) in exampleList" :key="index" @click="toExample(item.article_id)">
				
				<div class="example_right" v-if="!isPc">
					<!-- <img :src="httpsUrl+item.cover" /> -->
					<img :src="item.cover" />
				</div>
				<div class="example_left" >
					<p class="example_name">{{item.title}}</p>
					<p class="example_into">{{item.description}}</p>
					<div class="example_from">
						<div class="from_left">
							<img src="static/user.png" />
							<span>{{item.author}}</span>
						</div>
						<div class="from_right">
							{{item.time}}
						</div>
					</div>
				</div>
				<div class="example_right" v-if="isPc">
					<!-- <img :src="httpsUrl+item.cover" /> -->
					<img :src="item.cover" />
				</div>
					<div class="bottom_line"></div>
			</div>

		</div>
	</div>
</template>

<script>
	export default {
	   data(){
		   return{
				"on":true,
				exampleList:[
				// 	{
				// 	"title":'test',
				// 	"description":'1231233132',
				// 	"author":"九五云",
				// 	"time":'2020-8-8',
				// 	"cover":'static/dunload_bg.png'
				// },
				// {
				// 	"title":'test',
				// 	"description":'1231233132',
				// 	"author":"九五尊易九五云",
				// 	"time":'2020-8-8',
				// 	"cover":'static/dunload_bg.png'
				// },
				]
			}
	   },
	   mounted(){
		// 获取案例中心
		var that = this;
		const url =  this.httpsUrl+'/api/index.php?act=caseList';
		            this.$http.get(url).then(
		                response => {

		                    console.log(response.body);
							 this.exampleList = response.body.list;
		                    // this.repositoryUrl = result.html_url;
		                    // this.repositoryName = result.name;
		                },
		                response => {
		                    // alert('请求失败');
		                },
		            );
	  },
	   methods : {
		   // 去案例详情
		   toExample:function(id){
		   	 this.$router.push({path:"/templateDetail",query:{num:id}})
		   }

		},
	}
</script>

<style scoped="scoped">
	.banner{
		position: relative;
		width: 100%;
		overflow-x: hidden;
	}
	.example{
	/* 	width: 1000px;
		margin: 20px 0;
		text-align: center;
		left: 50%;
		margin-left: -500px;
		position: relative; */
		overflow: hidden;
		margin-bottom: 30px;
		position: relative;
		padding-bottom:40px ;

		margin-top: 30px;
		border-top: 1px solid #E6E6E6;
	}
	.example_content{
		width: 1000px;
		/* margin: 20px 0; */
		text-align: center;
		left: 50%;
		margin-left: -500px;
		position: relative;

		padding-top: 40px;
		padding-bottom: 30px;
		overflow: hidden;
		cursor: pointer;
	}
	.example_left{
		width: 50%;
		float: left;
		box-sizing: border-box;
		text-align: left;
		padding: 10px 20px;
	}
	.example_right{
		width: 50%;
		float: left;
	}
	.example_right img{
		width: 100%;
		height: 245px;
	}
	.example_name{
		color: #606060;
		text-align: left;
		height: 45px;
	}
	.example_into{
		margin: 10px 0;
		font-size: 13px;
		width: 100%;
		height: 130px;
		color: #909090;
	}
	.example_from{
		width: 100%;
		height: 30px;
	}
	.from_left{
		width: 50%;
		float: left;
	}
	.from_left img{
		width: 30px;
		height: 30px;
		border-radius: 50px;
		vertical-align: middle;

	}
	.from_left span{
		margin-left: 10px;
		font-size: 13px;
	}
	.from_right{
		width: 50%;
		float: right;
		line-height: 30px;
		color: #000000;
		text-align: right;
		font-size: 13px;

	}
	.bottom_line{
		width: 200px;
		border: 1px solid #E6E6E6;
		position: absolute;
		background-color: #E6E6E6;
		bottom: 0;
		left: 50%;
		margin-left: -100px;
	}
	@media (max-width:767px) {
		.example_content{
			width: 100% ;
			margin: 0 ;
			left: 0;
			padding: 10px;
			box-sizing: border-box;
			box-shadow: 5 5 5px #000;
			/* border-bottom:1px solid #E6E6E6 ; */
			box-shadow: 5px 5px 5px #F2F6FC ;
		}
		.example_left{
			width: 100%;
		}
		.example_right{
			width: 100%;
		}
		.bottom_line{
			display: none;
		}
		.example_name{
			height: auto;
		}
		.example_into{
			height: 50px;
			overflow: hidden;
		}
	}
</style>
